import React, { useEffect, useState } from 'react';
import { parse, isAfter, isBefore } from 'date-fns';
import { Line } from 'react-chartjs-2';
import Select from 'react-select';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const processes = [
    {
        id: 1,
        name: "Batch start"
    },
    {
        id: 2,
        name: "Pressure Hold"
    },
    {
        id: 3,
        name: "Esip"
    },
    {
        id: 4,
        name: "Fsip"
    },
    {
        id: 5,
        name: "Inoculation"
    },
    {
        id: 6,
        name: "Fermentation"
    },
    {
        id: 8,
        name: "CIP"
    },
];

const processObject = {
    1: "Batch start",
    2: "Pressure Hold",
    3: "Esip",
    4: "Fsip",
    5: "Inoculation",
    6: "Fermentation",
    8: "CIP"
};

const processObjectForStatus = {
    "HEATING": "Batch start",
    "HOLDING": "Pressure Hold",
    "COOLING": "Esip",
    "END": "Fsip",
    "PRESSURIZATION": "Inoculation",
    "PRESSURE HOLD": "Fermentation",
    "PRESSURE END": "",
    "STABILIZATION": "CIP"
};


const PDFDisplay = ({ textListEntries, headerOptions, csvData, client, equipment, equipmentId }) => {
    const [pdfStartDate, setPdfStartDate] = useState('');
    // const [pdfInitEndDate, setPdfInitEndDate] = useState('');
    // const [pdfInitStartDate, setPdfInitStartDate] = useState('');
    const [pdfEndDate, setPdfEndDate] = useState('');
    const [pdfBatchId, setPdfBatchId] = useState('');
    // const [selectedUser, setSelectedUser] = useState('');
    const [selectedProcessId, setSelectedProcess] = useState(null);
    const [selectedProcessName, setSelectedProcessName] = useState(null);
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);
    // const [filteredPdfData, setFilteredPdfData] = useState([]);
    const [dataKeys, setDataKeys] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [filters, setFilters] = useState({
        date: [],
        users: [],
        batchId: [],
        processes: []
    });
    const [showTable, setShowTable] = useState(false);
    const [minutes, setMinutes] = useState(5);
    const [seconds, setSeconds] = useState(0);

    // const formatTime = (value) => {
    //     if (value === 0) {
    //         return "All Data";
    //     } else if (value < 1) {
    //         // Convert to seconds if under 1 minute
    //         return `${Math.round(value * 60)} second${Math.round(value * 60) > 1 ? "s" : ""}`;
    //     } else {
    //         // Split into minutes and seconds
    //         const minutes = Math.floor(value);
    //         const seconds = Math.round((value - minutes) * 60);
    //         return seconds === 0
    //             ? `${minutes} minute${minutes > 1 ? "s" : ""}`
    //             : `${minutes} minute${minutes > 1 ? "s" : ""} ${seconds} second${seconds > 1 ? "s" : ""
    //             }`;
    //     }
    // };

    // const handleTimeIntervalChange = (e) => {
    //     let value = parseFloat(e.target.value);

    //     if (isNaN(value) || value < 0) {
    //         // Handle invalid or negative values
    //         setTimeFrame(0);
    //         setDisplayValue(formatTime(0));
    //         return;
    //     }

    //     // Cap at 5 minutes
    //     if (value > 5) {
    //         value = 5;
    //     }

    //     // Update state and display value
    //     setTimeFrame(value);
    //     setDisplayValue(formatTime(value));
    // };

    // const incrementTime = () => {
    //     // Increment by 15 seconds (0.25 minutes)
    //     let newValue = timeFrame + 0.25;
    //     if (newValue > 5) newValue = 5; // Cap at 5 minutes

    //     setTimeFrame(newValue);
    //     setDisplayValue(formatTime(newValue));
    // };


    // Helper to format the display value
    const formatTime = (mins, secs) => {
        let display = "";
        if (mins > 0) {
            display += `${mins} minute${mins > 1 ? "s" : ""}`;
        }
        if (secs > 0) {
            display += ` ${secs} second${secs > 1 ? "s" : ""}`;
        }
        return display || "0 seconds";
    };

    // Handle changes to minutes
    const handleMinutesChange = (e) => {
        let value = parseInt(e.target.value, 10);
        value = isNaN(value) || value < 0 ? 0 : value;
        setMinutes(value);
        // setDisplayValue(formatTime(value, seconds));
    };

    // Handle changes to seconds
    const handleSecondsChange = (e) => {
        let value = parseInt(e.target.value, 10);
        value = isNaN(value) || value < 0 ? 0 : value;
        if (value >= 60) {
            // Automatically convert seconds to minutes if >= 60
            const extraMinutes = Math.floor(value / 60);
            value = value % 60;
            setMinutes((prev) => prev + extraMinutes);
        }
        setSeconds(value);
        // setDisplayValue(formatTime(minutes, value));
    };

    // Filter the data based on minutes and seconds
    const handleFilter = () => {
        if (!filteredPdfData || filteredPdfData.length === 0) {
            setTimeFilteredData([]);
            setShowTable(false);
            return;
        }

        // Total time frame in seconds
        const totalSeconds = minutes * 60 + seconds;

        if (totalSeconds === 0) {
            // Return all data if totalSeconds is 0
            setTimeFilteredData(filteredPdfData);
            setShowTable(true);
            return;
        }

        // Calculate the difference in seconds between two data points
        const diff = Math.abs(
            parseInt(filteredPdfData[0][" Time "].split(":")[2]) -
            parseInt(filteredPdfData[1][" Time "].split(":")[2])
        );

        if (isNaN(diff) || diff === 0) {
            setTimeFilteredData(filteredPdfData);
            setShowTable(true);
            return;
        }

        const indexIncrement = Math.ceil(totalSeconds / diff);
        const selectedData = filteredPdfData.filter((_, idx) => idx % indexIncrement === 0);

        setTimeFilteredData(
            selectedData.map((item) => ({
                ...item,
                [" Status "]: statusConverter(item[" SubStatus "]),
            }))
        );
        setShowTable(true);
    };

    const handlePdfStartDateChange = (e) => {
        setPdfStartDate(e.target.value);
        setPdfBatchId("");
    };

    const handlePdfEndDateChange = (e) => {
        setPdfEndDate(e.target.value);
        setPdfBatchId("");
    };

    const handlePdfBatchChange = (e) => { setPdfBatchId(e.target.value); setTimeFilteredData([]); };
    // const handleUserChange = (e) => setSelectedUser(e.target.value);

    const handleHeaderChange = (selected) => {
        console.log(selected);
        const selectedHeaders = [
            "Date ",
            " Time ",
            " Batch_ID ",
            " Process ",
            " Status ",
            ...selected.map(o => o.value)];
        const selectedTableHeaders = [
            "Date",
            "Time",
            "Batch_ID",
            "Process",
            "Status",
            ...selected.map(o => o.label)
        ];
        console.log("selectedTableHeaders", selectedTableHeaders);
        setDataKeys(selectedHeaders);
        setTableHeaders(selectedTableHeaders);
        setSelectedHeaders(selected);
        setTimeFilteredData([]);
        // changeChartHeaders(selectedTableHeaders);
    };

    const handleProcessChange = (e) => {
        const selectedValue = Number(e.target.value); // Ensure the value is a number
        const process = processes.find((o) => o.id === selectedValue);
        setSelectedProcess(selectedValue);
        setSelectedProcessName(process?.name || '');
        console.log("selectedValue", selectedValue); // Debugging
        console.log("process", process); // Debugging
        setTimeFilteredData([]);
    };

    const parseDate = (dateStr) => {
        return parse(dateStr, 'd:M:yyyy', new Date());
    };

    const parseCustomDate = (dateString) => {
        const [day, month, year] = dateString.split(':').map(Number);
        return new Date(year, month - 1, day); // Create a Date object (month is 0-based)
    };
    // 1

    const startDate = pdfStartDate ? new Date(pdfStartDate).setHours(0, 0, 0, 0) : null;
    const endDate = pdfEndDate ? new Date(pdfEndDate).setHours(23, 59, 59, 999) : null;

    const filteredPdfData = csvData?.filter(row => {
        const date = parseCustomDate(row["Date "]).setHours(0, 0, 0, 0);
        // const startDate = pdfStartDate ? new Date(pdfStartDate) : null;
        // const endDate = pdfEndDate ? new Date(pdfEndDate) : null;
        const dateMatch =
            (!startDate || date >= startDate) &&
            (!endDate || date <= endDate);
        row[" Process "] = processObject[row[" Status "]];
        const batchIdMatch = pdfBatchId ? row[" Batch_ID "] === pdfBatchId : true;
        const processMatch = selectedProcessId ? row[" Status "]?.trim() === String(selectedProcessId) : true; // Ensure proper type comparison
        return dateMatch && processMatch && batchIdMatch;
    });

    const [timeFrame, setTimeFrame] = useState(0.25); // Default to 1 minute
    const [timeFilteredData, setTimeFilteredData] = useState(filteredPdfData || csvData || []);

    const setTimeInterval = (val) => {
        setTimeFrame(val)
    }

    const statusConverter = (status) => {
        return textListEntries[status];
    }

    // const handleFilter = () => {
    //     if (timeFrame == 0) {
    //         setTimeFilteredData(filteredPdfData);
    //         return;
    //     }
    //     let selectedData = [];
    //     const indexIncreament = Number(timeFrame * 6);

    //     for (let i = 0; i < filteredPdfData?.length; i += indexIncreament) {
    //         if (i || i >= 0)
    //             selectedData.push(filteredPdfData[i]);
    //     }

    //     selectedData = selectedData.map(o => { return { ...o, [" Status "]: statusConverter(o?.[" Status "]) } })
    //     setTimeFilteredData(selectedData);
    // };

    // const handleFilter = () => {
    //     if (!filteredPdfData || filteredPdfData.length === 0) {
    //         setTimeFilteredData([]);
    //         setShowTable(false);
    //         return;
    //     }

    //     // Calculate the difference in seconds between two data points
    //     let diff = Math.abs(
    //         parseInt(filteredPdfData[0][" Time "].split(":")[2]) -
    //         parseInt(filteredPdfData[1][" Time "].split(":")[2])
    //     );

    //     if (timeFrame === 0 || isNaN(diff) || diff === 0) {
    //         // If no timeframe is selected or diff is invalid, return the full data
    //         setTimeFilteredData(filteredPdfData);
    //         return;
    //     }

    //     let selectedData = [];
    //     const indexIncrement = Math.ceil((timeFrame * 60) / diff); // Increment index based on timeframe

    //     for (let i = 0; i < filteredPdfData.length; i += indexIncrement) {
    //         selectedData.push(filteredPdfData[i]);
    //     }

    //     // Apply status conversion for each item
    //     selectedData = selectedData.map((item) => ({
    //         ...item,
    //         [" Status "]: statusConverter(item[" Status "]),
    //     }));

    //     setTimeFilteredData(selectedData);
    //     setShowTable(true);
    // };


    // 2
    useEffect(() => {
        if (!(pdfStartDate && pdfEndDate)) return;
        const startDate = pdfStartDate ? new Date(pdfStartDate).setHours(0, 0, 0, 0) : null;
        const endDate = pdfEndDate ? new Date(pdfEndDate).setHours(23, 59, 59, 999) : null;
    
        const filteredData1 = filteredPdfData.filter(row => {
            const date = parseCustomDate(row["Date "]).setHours(0, 0, 0, 0); // Parse the custom date format
            // const startDate = pdfStartDate ? new Date(pdfStartDate) : null;
            // const endDate = pdfEndDate ? new Date(pdfEndDate) : null;

            // Check if the date falls within the range
            const dateMatch =
                (!startDate || date >= startDate) &&
                (!endDate || date <= endDate);

            const batchIdMatch = pdfBatchId ? row[" Batch_ID "] === pdfBatchId : true;
            const processMatch = selectedProcessId ? row[" Status "] == selectedProcessId : true;
            return (
                dateMatch &&
                // userMatch && 
                processMatch &&
                batchIdMatch
            );
        });

        const dates = [...new Set(filteredData1?.map(item => item["Date "]?.trim()))]?.filter(o => o);
        const users = [...new Set(filteredData1?.map(item => item[" User "]?.trim()))]?.filter(o => o);
        const batchId = [...new Set(filteredData1?.map(item => item[" Batch_ID "]?.trim()))]?.filter(o => o);
        const processObj = [...new Set(filteredData1?.map(item => item[" Status "]?.trim()))]?.filter(o => o);
        const filteredProcesseObj = processes.filter(o => processObj.includes(String(o.id)));

        setFilters({
            date: dates,
            users: users,
            batchId: batchId,
            processes: filteredProcesseObj
        });
        // setFilteredPdfData(filteredData1);

    }, [pdfStartDate, pdfEndDate])

    useEffect(() => {
        changeChartHeaders(tableHeaders);
    }, [timeFilteredData])

    const spValues = filteredPdfData?.length ? filteredPdfData[0] : {};

    const changeChartHeaders = (headers) => {
        const borderColor = [
            'rgba(75, 192, 192, 1)',   // Teal
            'rgba(255, 159, 64, 1)',   // Orange
            'rgba(153, 102, 255, 1)',  // Purple
            'rgba(255, 99, 132, 1)',   // Red
            'rgba(54, 162, 235, 1)',   // Blue
            'rgba(255, 206, 86, 1)',   // Yellow
            'rgba(75, 192, 192, 1)',   // Green
            'rgba(201, 203, 207, 1)',  // Light Grey
            'rgba(255, 205, 86, 1)',   // Gold
            'rgba(153, 102, 255, 1)'   // Violet
        ];
        console.log(headers);
        let chartData = {
            labels: timeFilteredData?.map((row) => {
                const date = row["Date "];   // Assuming " Date " is the date field in your data
                const time = row[" Time "];   // Assuming " Time " is the time field in your data
                return `${date}--${time}`;     // Combine date and time
            }),
            datasets: headers.slice(4)?.map((header, i) => {
                return {

                    label: header,
                    data: filteredPdfData?.map((row) => Number(row[` ${header} `])),
                    borderColor: borderColor[i],
                    fill: false,
                    borderWidth: 1.5, // Thinner line
                    lineTension: 0.3,  // Less curve in the line
                    pointRadius: 1.5,    // Smaller points // Smaller line width
                }
            })
        }
        console.log(chartData);
        setChartData(chartData);
    }
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
                text: 'Current Values Over Time',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Time',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Value',
                },
            },
        },
    };
    // Chart End
    return (
        <>
            <div className='row'>
                <div className="mb-4 col-4">
                    <label htmlFor="pdfStartDateFilter" className="mr-2">Start Date:</label>
                    <input type="date" id="pdfStartDateFilter" className="form-control d-inline-block w-100" value={pdfStartDate} onChange={handlePdfStartDateChange} />
                </div>
                <div className="mb-2 col-4">
                    <label htmlFor="pdfEndDateFilter" className="mr-2">End Date:</label>
                    <input type="date" id="pdfEndDateFilter" className="form-control d-inline-block w-100" value={pdfEndDate} onChange={handlePdfEndDateChange} />
                </div>

                {/* <div className="mb-2 col-1" style={{ marginTop: "33px" }}>
                    <button className="btn btn-primary" style={{ marginLeft: "-20px" }}>Change</button>
                </div> */}
                <div className="mb-4 col-4">
                    <label htmlFor="pdfBatchIdFilter" className="mr-2">Batch ID:</label>
                    <select id="pdfBatchIdFilter" className="form-control d-inline-block w-100" value={pdfBatchId} onChange={handlePdfBatchChange}>
                        <option value="">All Batch Ids</option>
                        {filters?.batchId?.map((id, index) => (
                            <option key={index} value={id}>{id}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4 col-4">
                    <label htmlFor="processFilter" className="mr-2">Process:</label>
                    <select
                        id="processFilter"
                        className="form-control d-inline-block w-100"
                        value={selectedProcessId}  // This will ensure the selected value shows up
                        onChange={handleProcessChange}
                    >
                        <option value="">All Processes</option>
                        {processes?.map((process, index) => (
                            <option key={index} value={process.id}>
                                {process.name.toUpperCase()}
                            </option>
                        ))}
                    </select>
                </div>
                {/* <div className="mb-4 col-4">
                    <label htmlFor="userFilter" className="mr-2">User:</label>
                    <select id="userFilter" className="form-control d-inline-block w-100" value={selectedUser} onChange={handleUserChange}>
                        <option value="">All Users</option>
                        {filters?.users?.map((user, index) => (
                            <option key={index} value={user}>{user}</option>
                        ))}
                    </select>
                </div> */}
                <div className='col-4 mb-4'>
                    <label htmlFor="idFilter" className="mr-2">Select Columns:</label>
                    <Select
                        isMulti
                        options={headerOptions}
                        value={selectedHeaders}
                        onChange={handleHeaderChange}
                        placeholder="Select headers..."
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                </div>
                {/* <div className="mb-4 col-2">
                    <label htmlFor="intervalInput" className="mr-2">Interval (minutes):</label>

                    <input
                        type="number"
                        id="intervalInput"
                        className="form-control"
                        value={timeFrame}
                        step={0.25}
                        min={0} // Minimum value is 15 seconds
                        max={5} // Maximum value is 5 minutes
                        onChange={handleTimeIntervalChange}
                        placeholder="Enter interval"
                    />
                </div>
                <p className='col-2'>
                    Selected Interval: <br /><strong>{displayValue}</strong>
                </p> */}
                <div className='col-4'>
                    <div className='col-6 d-inline-block'>
                        <label htmlFor="intervalInput" className="mr-2">minutes:</label>
                        <input
                            type="number"
                            value={minutes}
                            min="0"
                            onChange={handleMinutesChange}
                            placeholder="Minutes"
                            className="form-control p-1"
                        />
                    </div>
                    <div className='col-6 d-inline-block'>
                        <label htmlFor="intervalInput" className="mr-2">seconds:</label>
                        <input
                            type="number"
                            value={seconds}
                            min="0"
                            max="59"
                            onChange={handleSecondsChange}
                            placeholder="Seconds"
                            className="form-control p-1"
                        />
                    </div>
                </div>
                {/* <p className='col-2'>
                    Selected Interval: <strong><br/>{displayValue}</strong>
                </p> */}
                <div className="mb-4 container" style={{
                    "display": "block",
                    "width": "100%",
                    "textAlign": "right"
                }}>
                    {/* <button className="btn btn-secondary mr-2" onClick={handleClearFilters}>Clear Filters</button> */}

                    <button className="btn btn-primary" onClick={handleFilter}>Format Data</button>
                </div>
            </div>
            {
                pdfStartDate && pdfEndDate && pdfBatchId && tableHeaders && timeFilteredData.length ? (
                    <div id="pdf-content" className="pdf-display">
                        <h3>Process Report</h3>
                        <div>
                            <strong>COMPANY:</strong> {client}<br />
                            <strong>EQUIPMENT:</strong> {equipment}<br />
                            <strong>EQUIPMENT ID:</strong> {equipmentId}<br />
                            {/* <strong>PROCESS:</strong> {} */}
                        </div>

                        <h4 className='mt-4'>Set Parameters</h4>
                        <div>
                            <>
                                {tableHeaders.includes("TE_CV") && (
                                    <p className="m-0">
                                        <strong>TE Set Parameter:</strong>{" "}
                                        {spValues[" TE_SP "] ? `${spValues[" TE_SP "]} °C` : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("pH_CV") && (
                                    <p className="m-0">
                                        <strong>pH Set Parameter:</strong>{" "}
                                        {spValues[" pH_SP "]
                                            ? `${parseFloat(spValues[" pH_SP "]).toFixed(1)}`
                                            : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("DO_CV") && (
                                    <p className="m-0">
                                        <strong>DO Set Parameter:</strong>{" "}
                                        {spValues[" DO_SP "] ? `${spValues[" DO_SP "]} %` : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("RPM_CV") && (
                                    <p className="m-0">
                                        <strong>RPM Set Parameter:</strong>{" "}
                                        {spValues[" RPM_SP "] || "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("AIR_CV") && (
                                    <p className="m-0">
                                        <strong>AIR Set Parameter:</strong>{" "}
                                        {spValues[" AIR_SP "] ? `${spValues[" AIR_SP "]} LPM` : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("PR_CV") && (
                                    <p className="m-0">
                                        <strong>PR Set Parameter:</strong>{" "}
                                        {spValues[" PR_SP "] ? `${spValues[" PR_SP "]} bar` : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("O2_CV") && (
                                    <p className="m-0">
                                        <strong>O2 Set Parameter:</strong>{" "}
                                        {spValues[" O2_SP "] ? `${spValues[" O2_SP "]} LPM` : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("FSIP_TIME_CV") && (
                                    <p className="m-0">
                                        <strong>FSIP_TIME Set Parameter:</strong>{" "}
                                        {spValues[" FSIP_TIME_SP "]
                                            ? `${spValues[" FSIP_TIME_SP "]} minutes`
                                            : "N/A"}
                                    </p>
                                )}
                            </>



                        </div>
                        <h4 className='mt-4 mb-2'>Batch Data</h4>
                        <div>
                            <strong>EQUIPMENT ID:</strong> EUP-UPS-Q15<br />
                            {/* <strong>OPERATOR CODE:</strong> {selectedUser || 'All'}<br /> */}
                            <strong>DATE:</strong> {pdfStartDate} - {pdfEndDate}<br />
                            {/* <strong>TIME:</strong> {minTime}<br /> */}
                            <strong>PROCESS:</strong> {selectedProcessName || 'All'}<br />
                        </div>
                        <h4 className='mt-4 mb-2'>Current Values</h4>
                        <table style={{ borderCollapse: 'collapse', tableLayout: 'fixed', width: '100%' }}>
                            <thead>
                                <tr>
                                    {tableHeaders.map((header, index) => {
                                        const splitHeader = header.split('_'); // Split the header by the underscore
                                        const columnWidth = `${100 / tableHeaders.length}%`; // Calculate width based on number of columns
                                        return (
                                            <th key={index} style={{ ...headerStyle, width: columnWidth }}>
                                                {splitHeader[0]} <br /> {splitHeader[1]} {/* Display first part and second part on different lines */}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {timeFilteredData?.length > 0 && timeFilteredData?.map((row, index) => (
                                    <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                                        {dataKeys.map((key) => (
                                            // <td key={key} style={{ ...cellStyle, width: `${100 / tableHeaders.length}%`, textAlign: 'center' }}>
                                            <td key={key} style={{ ...cellStyle, width: `${100 / tableHeaders.length}%`, textAlign: 'center' }}>
                                                {/* Ensure two decimal places for numeric columns */}
                                                {[
                                                    "TE_CV",
                                                    "pH_CV", "DO_CV",
                                                    "RPM_CV",
                                                    "AIR_CV",
                                                    "PR_CV",
                                                    "O2_CV",
                                                    "LEVEL",
                                                    "IN_FL_TEMP",
                                                    "EX_FL_TEMP",
                                                    "DRAIN_TEMP",
                                                    "PH_TIME_CV",
                                                    "ESIP_TIME_CV",
                                                    "FSIP_TIME_CV",
                                                    "CIP_TIME_CV"
                                                ].includes(key.trim())
                                                    ? 
                                                    key.trim() === "CIP_TIME_CV" ? Number(row[" CIP_TIME_CV"]?.trim()).toFixed(2) || "N/A" : Number(row[key]?.trim()).toFixed(2) || "N/A"
                                                    : key.trim() === "Status" && !isNaN(Number(row[key])) ? statusConverter(row[" SubStatus "]) : key.trim() === "Process" ? row[" Process "] || ''
                                                    : row[key]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                {!filteredPdfData?.length && (
                                    <tr>
                                        <td colSpan={tableHeaders.length} style={{ textAlign: 'center' }}>No data found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <div className="page-break" style={{ pageBreakBefore: 'always' }}>
                            {/* Line Chart for current values */}
                            <div className="line-chart-container" style={{ width: '600px', height: '300px' }}>
                                {filteredPdfData.length > 0 && (
                                    <Line data={chartData} options={chartOptions} />
                                )}
                            </div>

                            {/* Existing table and PDF display code */}
                        </div>

                        {/* Additional Remarks and Signature Section */}
                        {pdfStartDate && pdfEndDate && pdfBatchId && (
                            <div id="pdf-content" className="pdf-display">
                                {/* Existing report content */}

                                {/* Page break for remarks and signature section */}
                                <div className="page-break">
                                    {/* Remarks Section */}
                                    <div className="mt-4">
                                        <h4>Remarks</h4>
                                        <textarea className="form-control" rows="4" placeholder="Enter any remarks here..."></textarea>
                                    </div>

                                    {/* Signature Section */}
                                    <div className="mt-5">
                                        <h4 className='mb-5'>Signatures</h4>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><strong>Done by:</strong></p>
                                                <p>____________________________</p>
                                            </div>
                                            <div className="col-4">
                                                <p><strong>Checked by:</strong></p>
                                                <p>____________________________</p>
                                            </div>
                                            <div className="col-4">
                                                <p><strong>Approved by:</strong></p>
                                                <p>____________________________</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : <>{Object.keys(spValues).length === 0 ? <div>No Data Available</div> : <div>Press Format Data Button</div>}</>
            }
        </>
    );
};

const cellStyle = {
    border: '1px solid #ddd',
    padding: '2px',
    textAlign: 'left',
    fontSize: '10px',
    wordWrap: 'break-word',
    overflow: 'hidden'
};

const headerStyle = {
    ...cellStyle,
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
    textAlign: 'center'
};

export default PDFDisplay;